<template>
    <v-app class = "fondo">
        <v-row>
            <v-col md="6">
                <v-container style = "margin-left: 10px;">
                    <h3>Contacto</h3><br>
                    Háganos su consulta y obtendrá respuesta a la brevedad.
                    <v-text-field
                        v-model="nombre"
                        label="Nombre"
                        autocomplete="nombre"
                    ></v-text-field>
                    <v-text-field
                        v-model="email"
                        label="E-mail"
                        autocomplete="email"
                        :rules = "reglasE"
                    ></v-text-field>
                    <v-textarea
                        label="Mensaje"
                        v-model="msj"
                    ></v-textarea>
                    <v-btn dark @click = "contactarse()">Enviar</v-btn>
                </v-container>
            </v-col>
            <v-col md = "6">
                <v-card class = "mx-auto pa-2" width = "415">
                    <v-card-text class = "headline">
                    <b>Información de contacto</b></v-card-text>
                    <v-container v-if = "isMobile()">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3112.7360860311946!2d-62.27117028423504!3d-38.72386777959783!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95edbcb6a46906d7%3A0xa57c7d95d3063a85!2sCaviglia%20y%20Tellarini%20SA!5e0!3m2!1ses-419!2sar!4v1578930734871!5m2!1ses-419!2sar" width="310" height="300" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
                    </v-container>
                    <v-container v-else>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3112.7360860311946!2d-62.27117028423504!3d-38.72386777959783!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95edbcb6a46906d7%3A0xa57c7d95d3063a85!2sCaviglia%20y%20Tellarini%20SA!5e0!3m2!1ses-419!2sar!4v1578930734871!5m2!1ses-419!2sar" width="380" height="300" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
                    </v-container>
                    <v-card-text>
                        <p class = "text--primary">
                          <v-icon>mdi-phone</v-icon>
                          0291 453-3038 / 456-5466</p>
                        <p class = "text--primary">
                          <v-icon class = "pa-1">mdi-whatsapp</v-icon>
                          <a target="_blank"
                          href = "https://api.whatsapp.com/send?phone=542914188756"
                          >0291 418-8756</a></p>
                        <p class = "text--primary">
                          <v-icon class = "pa-1">mdi-email</v-icon>
                          <a href = "mailto:info@exter.com.ar">info@exter.com.ar</a>
                        </p>
                        <b>Horarios de atención:</b><br>
                        <p class = "text-left ml-5 ">
                          Lunes a Viernes: De 8:00 a 12:30 y de 15:30 a 19:30 hs<br>
                          Sábado: De 8:30 a 13:00 hs<br>
                          Domingo: Cerrado </p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-app>
</template>
<script>
import axios from 'axios';
import swal from 'sweetalert2';
import { url } from '@/variables';

export default {
  name: 'Contacto',
  data: () => ({
    nombre: '',
    email: '',
    msj: '',
    reglasE: [
      v => !!v || 'El email es obligatorio.',
      v => /.+@.+\..+/.test(v) || 'El email debe ser válido.',
    ],
  }),
  methods: {
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
      }
      return false;
    },
    alertar(msj) {
      swal.fire({
        text: msj,
        icon: 'success',
      });
    },
    contactarse() {
      const path = `${url}/contacto`;
      axios.post(path, {
        nombre: this.nombre,
        email: this.email,
        msj: this.msj,
      })
        .then((response) => {
          if (response.status === 200) {
            if (response.data.success === true) {
              this.alertar('El email ha sido enviado correctamente');
            } else {
              this.alertar('Hubo un error enviando el email. Vuelva a intentar más tarde, o comuníquese con el local.');
            }
          }
        });
    },
  },
};
</script>
