<template>
  <v-app class = "fondo">
    <v-container>
      <h1 class = "text-left ml-3" v-text="descripcion">Nombre de producto</h1>
      <v-row>
        <v-col cols = "12" lg = "6" md = "12" sm = "12" class = "pa-6">
          <v-img
            :src = "foto"
            class = "grey lighten-2 mx-auto"
            max-height = "500"
            max-width = "400"
          >
          </v-img>
        </v-col>
        <v-col cols = "12" lg = "5" md = "12" sm = "12">
          <v-card flat color = "transparent">
            <v-card-text>
               <p class="Condensed Light"><b>Presentación:</b> {{ presentacion }}<br>
              <b>Tipo producto:</b> {{ tipoProd }}<br>
              <b>Categorías:</b> {{ categorias }}<br></p>
              <v-item-group class = "mt-2">
                <v-item
                  v-for="(etiq,i) in etiquetas"
                  :key="i"
                >
                <v-chip
                  label
                  outlined
                  class = "ma-1"
                  style = "font-size: small"
                  :href="etiq.href"
                >
                  <v-icon small left>mdi-tag</v-icon> {{ etiq.nombre }}
                </v-chip>
                </v-item>
              </v-item-group>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-tabs v-model = "tab" background-color = "transparent">
        <v-tab>Descripción</v-tab>
        <v-tab>Documentos</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" class = "fondo">
        <v-tab-item class = "pa-2">
          <span v-html = "descripLarga"></span>
        </v-tab-item>
        <v-tab-item class = "pa-2">
          <v-container v-if = "docs.length > 0">
            <v-item-group>
              <v-item
                v-for="(item,i) in docs"
                :key="i"
              >
                <a :href="item.href" v-text="item.filename"></a>
              </v-item>
            </v-item-group>
          </v-container>
          <v-container v-else>
            No se encontraron documentos relacionados.
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
    <v-divider></v-divider>
    <v-container>
      <h3 class="text-center headline">Productos relacionados</h3>
      <v-slide-group
        v-model="model"
        class="pa-4"
        show-arrows
      >
        <v-slide-item
          v-for="(item,n) in relacionados"
          :key="n"
        >
          <v-card
            class = "ma-1"
            height = "240"
            max-width = "170"
            outlined
            :href ='item.href'
          >
            <v-img contain :src='item.src' height="130" width="200"></v-img>
            <v-card-text v-text="item.text" class = "text-center titulo">
            </v-card-text>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </v-container>
  </v-app>
</template>
<script>
import axios from 'axios';
import { url } from '@/variables';

export default {
  name: 'Articulo',
  data: () => ({
    model: null,
    tab: null,
    codigo: 0,
    descripcion: '',
    descripLarga: '',
    foto: '',
    presentacion: '',
    tipoProd: '',
    categorias: '',
    docs: [],
    etiquetas: [],
    relacionados: [],
  }),
  methods: {
    init() {
      this.codigo = new URL(window.location.href).searchParams.get('id');
      const path = `${url}/getArticulo`;
      axios.post(path, {
        codigo: this.codigo,
      })
        .then((response) => {
          if (response.status === 200) {
            if (response.data.msg === 'Ok') {
              this.descripcion = response.data.datos[0].DESCRIPCION;
              this.descripLarga = response.data.datos[0].DESCRIPLARGA;
              this.foto = response.data.datos[0].NOMBREFOTO;
              this.presentacion = response.data.datos[0].PRESENTACION.toUpperCase();
              this.tipoProd = response.data.datos[0].TIPOPROD;
              this.categorias = response.data.categorias;
              this.docs = response.data.docs;
              this.relacionados = response.data.relacionados;
              this.etiquetas = response.data.etiquetas;
            }
          }
        });
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.init();
  },
};
</script>
