<template>
  <v-app>
    <v-app-bar
      app
      prominent
      flat class = "header"
      clipped-left
    >
      <v-app-bar-nav-icon @click="drawer = !drawer">
      </v-app-bar-nav-icon>
        <div style="margin-top: 15px;" >
          <a href ="/"><v-img
            src = "logo_caviglia.png"
            max-width = "300"
            href = "/"
          /></a>
        </div>
      <v-spacer></v-spacer>
      <div
        v-if = !isMobile() class = "col-3 text-right pa-0"
        style = "display: inline-grid; justify-items: end;"
      >
        <v-btn text tile small target="_blank"
        href = "https://api.whatsapp.com/send?phone=542914188756">
          <v-icon>mdi-whatsapp</v-icon> (0291) 418-8756
        </v-btn>
        <v-btn text tile small :ripple = "false">
          <v-icon>mdi-phone</v-icon> (0291) 453-3038 / 456-5466
        </v-btn>
        <v-btn text tile small :ripple = "false">
          <v-icon>mdi-home</v-icon> Donado 355 - Bahía Blanca - Bs. As.
        </v-btn>
        <v-btn text tile small href = "mailto:info@exter.com.ar">
          <v-icon>mdi-email</v-icon>info@exter.com.ar
        </v-btn>
      </div>
    </v-app-bar>
    <v-content>
      <v-container v-if = "isMobile()">
        <v-navigation-drawer
          v-model="drawer"
          fixed clipped
          class = "sidebar"
          >
          <v-list>
            <v-list-item class = "px-2">
              <v-btn icon @click.stop = "drawer = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item href="/">
              <v-list-item-icon>
                <v-icon>mdi-domain</v-icon>
              </v-list-item-icon>
              <v-list-item-content>Empresa</v-list-item-content>
            </v-list-item>
            <!-- Productos  -->
            <v-list-group
              prepend-icon="mdi-flask-empty-outline"
              @click.stop="goTo('productos')"
              value="true"
            >
              <template v-slot:activator>
                <v-list-item-title>Productos</v-list-item-title>
              </template>

              <v-list-group
                sub-group
                @click.stop="goTo('productos?f=lab')"
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>Laboratorios</v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item
                  v-for="(labo, i) in laboratorios"
                  :key="i"
                  :href="labo.href"
                >
                  <v-list-item-title v-text="labo.text"></v-list-item-title>
                </v-list-item>
              </v-list-group>

              <v-list-group
                sub-group
                @click.stop="goTo('productos?f=plaga')"
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>Plagas</v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item
                  v-for="(plaga, i) in plagas"
                  :key="i"
                  :href="plaga.href"
                >
                  <v-list-item-title v-text="plaga.text"></v-list-item-title>
                </v-list-item>
              </v-list-group>
            </v-list-group>
            <!-- Contacto -->
            <v-list-item href="/contacto">
              <v-list-item-icon>
                <v-icon>mdi-email</v-icon>
              </v-list-item-icon>
              <v-list-item-content>Contacto</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </v-container>
      <v-container v-else>
        <v-navigation-drawer app
        mobile-break-point = "800"
        v-model="drawer"
        value = "true"
        fixed clipped
        class = "sidebar"
        style = "top: 20px; margin-top: 100px;"
        >
          <v-list nav>
            <v-list-item href="/">
              <v-list-item-icon>
                <v-icon>mdi-domain</v-icon>
              </v-list-item-icon>
              <v-list-item-content>Empresa</v-list-item-content>
            </v-list-item>
            <!-- Productos  -->
            <v-list-group
              prepend-icon="mdi-flask-empty-outline"
              @click.stop="goTo('productos')"
            >
              <template v-slot:activator>
                <v-list-item-title>Productos</v-list-item-title>
              </template>

              <v-list-group
                no-action
                sub-group
                @click="goTo('productos?f=1')"
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>Laboratorios</v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item
                  v-for="(labo, i) in laboratorios"
                  :key="i"
                  :href="labo.href"
                >
                  <v-list-item-title v-text="labo.text"></v-list-item-title>
                </v-list-item>
              </v-list-group>

              <v-list-group
                sub-group
                no-action
                @click="goTo('productos?f=2')"
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>Plagas</v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item
                  v-for="(plaga, i) in plagas"
                  :key="i"
                  :href="plaga.href"
                >
                  <v-list-item-title v-text="plaga.text"></v-list-item-title>
                </v-list-item>
              </v-list-group>
            </v-list-group>
            <!-- Contacto -->
            <v-list-item href="/contacto">
              <v-list-item-icon>
                <v-icon>mdi-email</v-icon>
              </v-list-item-icon>
              <v-list-item-content>Contacto</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </v-container>
      <v-container fluid v-if = "!isMobile()"
       style = "padding-left: 80px;">
        <router-view ref = "vista"></router-view>
      </v-container>
      <v-container v-else>
        <router-view ref = "vista"></router-view>
      </v-container>
    </v-content>
    <div v-if = "noEsProducto()">
      <v-footer app dark>
        <v-col class = "text-right">
          Copyright © 2020 EXTER!. Todos los derechos reservados. Diseñado por:
          <a
           href = "http://www.threads-srl.com.ar/"
           class = "link">Threads S.R.L.</a>
        </v-col>
      </v-footer>
    </div>
    <div v-else>
      <v-footer dark>
        <v-col class = "text-right">
          Copyright © 2020 EXTER!. Todos los derechos reservados. Diseñado por:
          <a
           href = "http://www.threads-srl.com.ar/"
           class = "link">Threads S.R.L.</a>
        </v-col>
      </v-footer>
    </div>
  </v-app>
</template>
<style>
  .v-list-item--active {
    color: #e85252 !important;
  }
  .v-ripple__container {
    color: #e85252 !important;
  }
  .link {
    color: #929292 !important;
    text-decoration: none;
  }
  .link:hover {
    color: #63b563 !important;
    text-decoration: underline;
  }
  .sidebar {
    background-color: rgb(242, 240, 198) !important;
  }
  .header {
    background-color: rgb(217, 232, 199) !important;
  }
</style>
<script>
import axios from 'axios';
import { url } from '@/variables';

export default {
  name: 'App',
  data: () => ({
    drawer: false,
    mini: false,
    stop: false,
    laboratorios: [
      { text: 'ACME', href: 'productos?labo=acme' },
      { text: 'BAYER', href: 'productos?labo=bayer' },
    ],
    plagas: [
      { text: 'ABEJAS', href: 'productos?plaga=abejas' },
      { text: 'ARAÑA', href: 'productos?plaga=arania' },
    ],
  }),
  methods: {
    init() {
      const path = `${url}/listarCategs`;
      axios.get(path)
        .then((response) => {
          if (response.status === 200) {
            this.laboratorios = response.data.laboratorios;
            this.plagas = response.data.plagas;
          }
        });
    },
    isMobile() {
      if ((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) || (window.innerWidth < 900)) {
        return true;
      }
      return false;
    },
    goTo(destino) {
      this.$router.push(destino);
    },
    noEsProducto() {
      return (this.$router.history.current.path !== '/productos');
    },
  },
  mounted() {
    this.drawer = !this.isMobile();
    this.mini = !this.isMobile();
    this.init();
    this.$refs.vista.$el.focus();
  },
};
</script>
