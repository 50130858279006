<template>
    <v-app class = "fondo">
        Servicios
    </v-app>
</template>
<script>
export default {
  name: 'Servicio',
};
</script>
