<!-- eslint-disable max-len -->

<template>

  <v-app class="fondo">

    <v-container>

      <v-row>

        <v-col class="col-md-7">

          <v-card flat min-width="300" color="transparent">

            <h3 class="font-weight-bold">¡Bienvenidos!</h3>

            <p class="font-weight-light">
               Caviglia & Tellarini SA - EXTER!, es una empresa cuyo accionar esta orientado a apoyar
              el desarrollo agro-urbano creando óptimas condiciones de sanidad ambiental por medio del
              manejo integrado de plagas, la implementación de normas de inocuidad y la distribución
              de productos agroquímicos y de saneamiento.
            </p>

            <b>

              <p>
                 EXTER! tiene más de 65 años de experiencia en desratizaciones, desinsectaciones y desinfecciones.

              </p>

            </b>

          </v-card>

        </v-col>

        <v-col class="col-md-5">

          <v-card flat color="transparent">

            <h3 class="font-weight-bold">Ofrecemos</h3>

            <v-list flat dense disabled color="transparent">

              <v-list-item-group>

                <v-list-item>

                  <v-list-item-icon>

                    <v-icon>mdi-check-bold</v-icon>

                  </v-list-item-icon>

                  <v-list-item-content>

                    <v-list-item-title>Control de plagas</v-list-item-title>

                  </v-list-item-content>

                </v-list-item>

                <v-list-item>

                  <v-list-item-icon>

                    <v-icon>mdi-check-bold</v-icon>

                  </v-list-item-icon>

                  <v-list-item-content>

                    <v-list-item-title>Agroquímicos</v-list-item-title>

                  </v-list-item-content>

                </v-list-item>

                <v-list-item>

                  <v-list-item-icon>

                    <v-icon>mdi-check-bold</v-icon>

                  </v-list-item-icon>

                  <v-list-item-content>

                    <v-list-item-title>Fumigación</v-list-item-title>

                  </v-list-item-content>

                </v-list-item>

                <v-list-item two-line>

                  <v-list-item-icon>

                    <v-icon>mdi-check-bold</v-icon>

                  </v-list-item-icon>

                  <v-list-item-content>

                    <v-list-item-title style="overflow: unset">
                       Servicios de desinfección por COVID-19 en
                    </v-list-item-title>

                    <v-list-item-title style="overflow: unset">
                       oficinas, depósitos, casas, departamentos, etc.
                    </v-list-item-title>

                  </v-list-item-content>

                </v-list-item>

              </v-list-item-group>

            </v-list>

          </v-card>

        </v-col>

      </v-row>

      <v-row>

        <v-hover v-slot:default="{ hover }" open-delay="200">

          <v-card class="ma-3 ml-5" href="productos?f=1" :elevation="hover ? 16 : 2" height="250">

            <v-img height="200" width="200" src="laboratorio.png"> </v-img>

            <v-card-text class="text-center title">Laboratorios</v-card-text>

          </v-card>

        </v-hover>

        <v-hover v-slot:default="{ hover }" open-delay="200">

          <v-card class="ma-3 ml-5" href="productos?f=2" :elevation="hover ? 16 : 2" height="250">

            <v-img height="200" width="200" contain src="plaga.jpg"> </v-img>

            <v-card-text class="text-center title">Plagas</v-card-text>

          </v-card>

        </v-hover>

      </v-row>

    </v-container>

    <v-dialog v-model="model" @keydown.esc="model = false" max-width="500">

      <v-card class="animate__animated animate__bounce" width="500">

        <v-toolbar flat>

          <v-toolbar-title></v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn icon @click="model=false">

            <v-icon>mdi-close</v-icon>

          </v-btn>

        </v-toolbar>

          <v-img width="500" src="../assets/palomexter.jpg"> </v-img>

        <v-card-actions>

          <v-list>

            <v-list-item target="_blank" href="https://api.whatsapp.com/send?phone=542914188756">

              <v-list-item-icon dense class="mr-1">

                <v-icon color="green" class="mr-1">mdi-whatsapp</v-icon>

              </v-list-item-icon>

              <v-list-item-content class="green--text"> (0291) 418-8756 </v-list-item-content>

            </v-list-item>

          </v-list>

        </v-card-actions>

      </v-card>

    </v-dialog>

  </v-app>

</template>

<style scoped>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.7;
  position: absolute;
  width: 100%;
}

</style>

<script>
export default { name: 'Empresa', data: () => ({ model: true }) };
</script>
