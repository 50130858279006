<template>
    <v-app class = "fondo">
      <div id = "busqueda">
        <v-text-field
          append-icon="mdi-magnify"
          outlined
          clearable
          placeholder="Buscar..."
          v-model="searchText">
        </v-text-field>
      </div>
      <v-container fluid v-if="noElementos">
        No se encontraron coincidencias.
      </v-container>
      <v-container v-if="busquedaXEtiqueta()">
        Resultados de la búsqueda: {{ etiqueta }}
      </v-container>
      <v-container fluid grid-list-md v-if = "isMobile()">
          <v-layout row>
              <v-flex
                v-for="(item,i) in filteredList"
                :key="i"
                shrink
                class = "pa-1">
                <v-hover
                  v-slot:default="{ hover }"
                  open-delay="200"
                >
                  <v-card
                    height = "200"
                    max-width = "150"
                    outlined
                    :elevation="hover ? 8 : 2"
                    :href ='item.href'>
                        <v-img contain :src='item.src' height="120" width="200"></v-img>
                      <v-card-text v-text="item.text" class = "text-center titulo"></v-card-text>
                  </v-card>
                </v-hover>
              </v-flex>
          </v-layout>
      </v-container>
      <v-container fluid grid-list-md v-else>
        <v-layout row>
              <v-flex
                v-for="(item,i) in filteredList"
                :key="i"
                align-self-start
                shrink
                class = "ma-1">
                <v-hover
                  v-slot:default="{ hover }"
                  open-delay="200"
                >
                  <v-card
                    height = "200"
                    max-width = "200"
                    outlined
                    :elevation="hover ? 8 : 2"
                    :href ='item.href'>
                        <v-img contain :src='item.src' height="120" width="200"></v-img>
                      <v-card-text v-text="item.text" class = "text-center titulo"></v-card-text>
                  </v-card>
                </v-hover>
              </v-flex>
          </v-layout>
      </v-container>
   </v-app>
</template>
<style>
.titulo {
    font-weight: bolder;
    font-size: 15px;
}

#busqueda {
  display: block;
  width: 330px;
  float: right;
  align-self: end;
}

</style>
<script>
import axios from 'axios';
import { url } from '@/variables';

export default {
  name: 'Productos',
  data: () => ({
    items: [],
    searchText: '',
    etiqueta: '',
    noElementos: false,
  }),
  methods: {
    init() {
      const fGeneral = new URL(window.location.href).searchParams.get('f');
      const fTag = new URL(window.location.href).searchParams.get('t');
      const fLabo = new URL(window.location.href).searchParams.get('labo');
      const fPlaga = new URL(window.location.href).searchParams.get('plaga');
      if (fGeneral != null) {
        this.listarSubC(fGeneral);
      } else {
        this.filtrar(fLabo, fPlaga, fTag);
      }
    },
    isMobile() {
      if ((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) || (window.innerWidth < 900)) {
        return true;
      }
      return false;
    },
    filtrar(labo, plagas, tag) {
      const path = `${url}/getProductos`;
      axios.post(path, {
        fTag: tag,
        fLabo: labo,
        fPlaga: plagas,
      })
        .then((response) => {
          if (response.status === 200) {
            if (response.data.msg === 'Ok') {
              this.items = response.data.datos;
              this.noElementos = (this.items.length === 0);
            }
          }
        });
    },
    listarSubC(f) {
      const path = `${url}/getSubCategs`;
      axios.post(path, {
        codigo: f,
      })
        .then((response) => {
          if (response.status === 200) {
            if (response.data.msg === 'Ok') {
              this.items = response.data.datos;
              this.noElementos = (this.items === 0);
            }
          }
        });
    },
    busquedaXEtiqueta() {
      const codEtiqueta = new URL(window.location.href).searchParams.get('t');
      const esBusqPorEtiq = codEtiqueta !== null;
      if (esBusqPorEtiq) {
        const path = `${url}/getNombreEtiqueta`;
        axios.post(path, {
          codigo: codEtiqueta,
        })
          .then((response) => {
            if (response.status === 200) {
              this.etiqueta = response.data.nombre;
            }
          });
      }
      return (esBusqPorEtiq);
    },
    filtrarBusqueda() {
      const toRet = [];
      this.items.forEach((val) => {
        if (val.text.toLowerCase().includes(this.searchText.toLowerCase())) {
          toRet.push(val);
        } else {
          val.tag.forEach((tag) => {
            if (tag.toLowerCase().includes(this.searchText.toLowerCase())) {
              toRet.push(val);
            }
          });
        }
      });
      return toRet;
    },
  },
  mounted() {
    this.init();
  },
  watch: {
    $route() {
      this.init();
    },
  },
  computed: {
    filteredList() {
      if (this.searchText) {
        return this.filtrarBusqueda();
      }
      return this.items;
    },
  },
};
</script>
