import Vue from 'vue';
import Router from 'vue-router';
import Principal from './components/Principal.vue';
import Empresa from './components/Empresa.vue';
import Productos from './components/Productos.vue';
import Servicios from './components/Servicios.vue';
import Contacto from './components/Contacto.vue';
import Articulo from './components/Articulo.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: Principal,
      children: [
        {
          path: '/',
          name: 'Empresa',
          component: Empresa,
        },
        {
          path: '/productos',
          name: 'Productos',
          component: Productos,
        },
        {
          path: '/servicios',
          name: 'Servicios',
          component: Servicios,
        },
        {
          path: '/contacto',
          name: 'Contacto',
          component: Contacto,
        },
        {
          path: '/articulo',
          name: 'Articulo',
          component: Articulo,
        },
      ],
    },
  ],
});
